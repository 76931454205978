import logo from '../img/logo2.png';
import emailjs from 'emailjs-com';
import $, { now } from 'jquery';
import 'bootstrap/js/dist/modal.js';
import { projectFirestore } from '../firebase'
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import '../inscription.css';

function Inscription({ match }) {

    const history = useHistory();

    const initialFieldValue = {
        nom: '',
        prenom: '',
        birthdate: '',
        sejour: '',
        date_aller: '',
        date_retour: '',
        sexe: '',
        age: '',
        adresse: '',
        code_postal: '',
        ville: '',
        tel_mere: '',
        tel_pere: '',
        tel_enfant: '',
        email_mere: '',
        email_pere: '',
        email_enfant: '',
        ecole: '',
        premier_sejour: '',
        taille: '',
        pointure: '',
        poids: '',
        ski: '',
        casque: '',
        t_cotisation: 0,
        t_montant_du_sejour: 0,
        t_transport: 0,
        t_assurance: 0,
        t_supplement: 0,
        t_total: 0,
        s_date: new Date().toLocaleDateString('fr-FR'),
        s_name: '',
        s_location: '',
    }

    // const initialFieldValue = {
    //     nom: '',
    //     prenom: '',
    //     birthdate: '',
    //     sejour: '',
    //     date_aller: '',
    //     date_retour: '',
    //     sexe: 'M',
    //     age: '',
    //     adresse: '121 rue de longchamp',
    //     code_postal: '92200',
    //     ville: 'Neuilly-sur-seine',
    //     tel_mere: '0622801930',
    //     tel_pere: '0611484514',
    //     tel_enfant: '0686748507',
    //     email_mere: 'alexandra@alexandrapr.com',
    //     email_pere: 'sbennaim@bennaim.fr',
    //     email_enfant: 'dh.bennaim@hotmail.fr',
    //     ecole: 'Epitech Paris',
    //     premier_sejour: 'NON',
    //     taille: 185,
    //     pointure: 44,
    //     poids: 75,
    //     ski: 'Compétition',
    //     casque: 'NON',
    //     t_cotisation: 0,
    //     t_montant_du_sejour: 5555,
    //     t_transport: 0,
    //     t_assurance: 0,
    //     t_supplement: 0,
    //     t_total: 5600,
    //     s_date: new Date().toLocaleDateString('fr-FR'),
    //     s_name: "Stephane Bennaim",
    //     s_location: "Paris",
    // }

    var [values, setValues] = useState(initialFieldValue);
    var [sejour, setSejour] = useState();
    var [showInfo, setShowInfo] = useState(false);
    var [validate, setValidate] = useState(true);
    var [ref, setRef] = useState(true);

    const calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        if (age_now)
            return age_now;
        else
            return '';
    }

    useEffect(async () => {
        setValues({ ...initialFieldValue })
        await projectFirestore.collection('i_inscriptions').doc(match.params.id).get().then(
            async (doc) => {
                if (doc.exists && doc.data().ref == match.params.ref && doc.data().fi_a_remplir == true) {
                    projectFirestore.collection('i_inscriptions').doc(match.params.id).collection('Info').doc('inscription').get().then(
                        async (doc) => {
                            if (!doc.exists) {
                                history.push("/404")
                            } else {
                                setValues({
                                    ...doc.data()
                                })
                            }
                        }
                    )
                    setShowInfo(true);
                } else if (!doc.exists || match.params.ref || doc.data().fi_a_remplir != true || doc.data().fi_remplie != false) {
                    history.push("/404")
                } else {
                    var sejourObj = []
                    await projectFirestore.collection('Sejours').doc(doc.data().sejour).get().then((obj) => {
                        sejourObj.push({
                            nom_du_sejour: obj.data().nom_du_sejour,
                            date_aller: new Date(obj.data().date_aller.seconds * 1000).toISOString().slice(0, 10),
                            date_retour: new Date(obj.data().date_retour.seconds * 1000).toISOString().slice(0, 10),
                            montant_sejour: obj.data().montant_sejour,
                            montant_transport: obj.data().montant_transport,
                            type_sejour: obj.data().type_sejour,
                        })
                        setRef(obj.data().ref);
                    })
                    var cotisation = await (await projectFirestore.collection('i_inscriptions').doc(match.params.id).get()).data().cotisation
                    setValues({
                        ...values,
                        nom: doc.data().nom,
                        prenom: doc.data().prenom,
                        birthdate: new Date(doc.data().birthdate.seconds * 1000).toISOString().slice(0, 10),
                        age: calculate_age(new Date(doc.data().birthdate.seconds * 1000).toISOString().slice(0, 10)),
                        sejour: sejourObj[0].nom_du_sejour,
                        date_aller: sejourObj[0].date_aller,
                        date_retour: sejourObj[0].date_retour,
                        t_cotisation: cotisation == "OUI" ? 20 : 0,
                        t_montant_du_sejour: sejourObj[0].montant_sejour,
                        t_transport: sejourObj[0].montant_transport,
                        type_sejour: sejourObj[0].type_sejour
                    })
                    setShowInfo(false);

                }
                setValidate(doc.data().fi_verifier ? false : true)
            }
        )
        // eslint-disable-next-line
    }, [match.params]);

    const handleInputChange = e => {
        var { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleInputSubmit = async e => {
        e.preventDefault();
        setShowInfo(false);
        await projectFirestore.collection('i_inscriptions').doc(match.params.id).collection('Info').doc('inscription').set(values);
        await projectFirestore.collection('i_notifications').doc().set({
            createdAt: new Date(),
            tag: 'fi_to_validate',
            message: "Fiche d'inscription de " + values.nom + " " + values.prenom + "a verifier",
            archived: false,
        });
        await projectFirestore.collection('i_inscriptions').doc(match.params.id).update({ fi_remplie: true, lastUpdated: new Date() });
        $('#confirmation').modal('show')
    }

    const redirect = () => {
        window.location.href = "https://inscription.yaniv.fr/fs/" + match.params.id;
    }

    return (
        <>
            <div className="i_centered mt-3" style={{ position: 'absolute', zIndex: 9 }}>
                {showInfo && match.params.ref ? <input type="button" className="btn btn-warning" value="Telecharger" onClick={
                    () => {
                        const input = document.getElementById("test");

                        html2canvas(input, {})
                            .then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                var doc = document.createElement('a');
                                doc.download = 'Inscription_' + values.nom + '_' + values.prenom + '.png';
                                doc.href = imgData;
                                doc.click();
                            });
                    }
                } /> : null}


                {/* showInfo && match.params.ref && validate ? <input type="button" className="btn btn-success ml-5" value="Valider La Fiche D'inscription" onClick={
                    async () => {
                        await projectFirestore.collection('i_inscriptions').doc(match.params.id).update({ fi_verifier: true, fi_a_remplir: true, fi_remplie: true })
                        setValidate(false);
                    }
                } /> : null */}
            </div>
            <form onSubmit={handleInputSubmit}>
                <div className="wrapper" id="test">
                    <div className="">
                        <div className="i_split i_left">
                            <div className="row i_centered">
                                <div className="col-sm-12" >
                                    <img src="YANIV_LOGO_Y_BLANC-rogner.png" alt="" />
                                </div>
                            </div>
                            <div className="row i_centered">
                                <div className="col">
                                    <h5>FICHE D’INSCRIPTION - YANIV {values.type_sejour == "ete" ? "ETE ".concat(new Date().getFullYear(values.date_aller)) : values.type_sejour == "automne" ? "AUTOMNE ".concat(new Date().getFullYear(values.date_aller)) : "HIVER ".concat(new Date().getFullYear(values.date_aller)).concat("-").concat(new Date().getFullYear(values.date_retour))}</h5>
                                </div>
                            </div>
                            <br />
                            <div className="row input-group">
                                <div className="col-sm">
                                    <label>Sejour :</label>
                                    <input type="text" className="col form-control" name="sejour" value={values.sejour} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-sm">
                                    <label>Du :</label>
                                    <input type="date" className="col form-control" name="date_aller" value={values.date_aller} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-sm">
                                    <label>Au :</label>
                                    <input type="date" className="col form-control" name="retour" value={values.date_retour} onChange={handleInputChange} disabled />
                                </div>
                            </div>
                            <div className="row input-group">
                                <div className="col-sm">
                                    <label>Nom :</label>
                                    <input type="text" className="col form-control" name="nom" value={values.nom} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-sm">
                                    <label>Prenom :</label>
                                    <input type="text" className="col form-control" name="prenom" value={values.prenom} onChange={handleInputChange} disabled />
                                </div>
                            </div>
                            <div className="row input-group">
                                <div className="col-sm-2">
                                    <label>Sexe :</label><br />
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="sexe" id="F" value="F" onChange={handleInputChange} checked={values.sexe == 'F' ? true : false} />
                                        <label className="form-check-label" htmlFor="F">F</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="sexe" id="M" value="M" onChange={handleInputChange} checked={values.sexe == 'M' ? true : false} />
                                        <label className="form-check-label" htmlFor="M">M</label>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <label>Date de Naissance :</label>
                                    <input type="date" className="col form-control" name="birthdate" value={values.birthdate} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-sm">
                                    <label>Age :</label>
                                    <input type="text" className="col form-control" name="age" value={calculate_age(values.birthdate)} onChange={handleInputChange} disabled />
                                </div>
                            </div>
                            <div className="row input-group">
                                <div className="col-sm-7">
                                    <label>Adresse :</label>
                                    <textarea name="adresse" className="col form-control" rows="5" value={values.adresse} onChange={handleInputChange}></textarea>
                                </div>
                                <div className="col">
                                    <br />
                                    <div className="row">
                                        <div className="col">
                                            <label>Code Postal :</label><br />
                                            <input type="text" className="col-sm-4 form-control" name="code_postal" value={values.code_postal} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label>Ville :</label>
                                            <input type="text" className="col form-control" name="ville" value={values.ville} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row input-group">
                                <div className="col-sm">
                                    <label>Numero de téléphone (mère) :</label>
                                    <input type="number" className="col form-control" name="tel_mere" value={values.tel_mere} onChange={handleInputChange} />
                                </div>
                                <div className="col-sm">
                                    <label>(père) :</label>
                                    <input type="number" className="col form-control" name="tel_pere" value={values.tel_pere} onChange={handleInputChange} />
                                </div>
                                <div className="col-sm">
                                    <label>(enfant)</label>
                                    <input type="number" className="col form-control" name="tel_enfant" value={values.tel_enfant} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="row input-group">
                                <div className="col-sm">
                                    <label>E-mail (mère) :</label>
                                    <input type="email" className="col form-control" name="email_mere" value={values.email_mere} onChange={handleInputChange} />
                                </div>
                                <div className="col-sm">
                                    <label>(père) :</label>
                                    <input type="email" className="col form-control" name="email_pere" value={values.email_pere} onChange={handleInputChange} />
                                </div>
                                <div className="col-sm">
                                    <label>(enfant)</label>
                                    <input type="email" className="col form-control" name="email_enfant" value={values.email_enfant} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="row input-group">

                                <div className="col-sm-4">
                                    <label>Établissement scolaire de l'enfant :</label>
                                    <input type="text" className="col form-control" name="ecole" value={values.ecole} onChange={handleInputChange} />
                                </div>
                                <div className="col-sm-3">
                                    <label>Premier séjour à YANIV ?</label><br />

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="premier_sejour" id="Premier-Sejour-OUI"
                                            value="OUI" onChange={handleInputChange} checked={values.premier_sejour == "OUI" ? true : false} />
                                        <label className="form-check-label" htmlFor="Premier-Sejour-OUI">OUI</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="premier_sejour" id="Premier-Sejour-NON"
                                            value="NON" onChange={handleInputChange} checked={values.premier_sejour == "NON" ? true : false} />
                                        <label className="form-check-label" htmlFor="Premier-Sejour-NON">NON</label>
                                    </div>
                                </div>
                            </div>
                            {(values.type_sejour == "ete" || values.type_sejour == "automne") ?
                                null :
                                <div className="row input-group">

                                    <div className="col-sm">
                                        <label>Taille (cm) :</label>
                                        <input type="number" className="col form-control" name="taille" value={values.taille} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-sm">
                                        <label>Pointure :</label>
                                        <input type="number" className="col form-control" name="pointure" value={values.pointure} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-sm">
                                        <label>Poids (kg) :</label>
                                        <input type="number" className="col form-control" name="poids" value={values.poids} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-sm-4">
                                        <label>Niveau de ski :</label><br />
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ski" id="Ski-Debutant" value="Débutant" onChange={handleInputChange} checked={values.ski == "Débutant" ? true : false} />
                                            <label className="form-check-label" htmlFor="Ski-Debutant">Débutant</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ski" id="Ski-1" value="1ère étoile" onChange={handleInputChange} checked={values.ski == "1ère étoile" ? true : false} />
                                            <label className="form-check-label" htmlFor="Ski-1">1*</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ski" id="Ski-2" value="2ème étoile" onChange={handleInputChange} checked={values.ski == "2ème étoile" ? true : false} />
                                            <label className="form-check-label" htmlFor="Ski-2">2*</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ski" id="Ski-3" value="3ème étoile" onChange={handleInputChange} checked={values.ski == "3ème étoile" ? true : false} />
                                            <label className="form-check-label" htmlFor="Ski-3">3*</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ski" id="Compétition"
                                                value="Compétition" onChange={handleInputChange} checked={values.ski == "Compétition" ? true : false} />
                                            <label className="form-check-label" htmlFor="Ski-Competition">Compétition</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <label>Casque <small>(offert par YANIV)</small> :</label><br />
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="casque" id="Casque-OUI" value="OUI" onChange={handleInputChange} checked={values.casque == "OUI" ? true : false} />
                                            <label className="form-check-label" htmlFor="Casque-OUI">OUI</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="casque" id="Casque-NON" value="NON" onChange={handleInputChange} checked={values.casque == "NON" ? true : false} />
                                            <label className="form-check-label" htmlFor="Casque-NON">NON</label>
                                        </div>
                                    </div>
                                </div>

                            }
                            <br />

                            <br /><br />
                            <div className="form-row">
                                <div className="col-md">
                                    <label>Cotisation
                                        <br /><small>(20€)</small> :</label>
                                    <input type="text" className="col form-control" name="t_cotisation" value={values.t_cotisation} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-md">
                                    <label>Montant du séjour :</label>
                                    <input type="text" className="col form-control" name="t_montant_du_sejour" value={values.t_montant_du_sejour} onChange={handleInputChange} disabled />
                                </div>
                                <div className="col-md">
                                    <label>Transport :</label>
                                    <input type="text" className="col form-control" name="t_transport" value={values.t_transport} onChange={handleInputChange} />
                                </div>
                                <div className="col-md-3">
                                    <label>Assurance annulation*
                                        <br /><small>(50€ - facultative mais
                                            recommandée)</small> :</label>
                                    <input type="text" className="col form-control" name="t_assurance" value={values.t_assurance} onChange={handleInputChange} />
                                </div>
                                {
                                    (values.type_sejour == "ete" || values.type_sejour == "automne") ?
                                        null :
                                        <div className="col-md">
                                            <label>Supplément
                                                Snowboard
                                                <br /><small>(55€ ou 65€)</small> :</label>
                                            <input type="text" className="col form-control" name="t_supplement" value={values.t_supplement} onChange={handleInputChange} />
                                        </div>
                                }
                                <div className="col-md">
                                    <label>Total :</label>
                                    <input type="text" className="col form-control" name="t_total"
                                        value={parseInt(values.t_cotisation) + parseInt(values.t_montant_du_sejour) + parseInt(values.t_transport) + parseInt(values.t_assurance ? values.t_assurance : 0) + parseInt(values.t_supplement ? values.t_supplement : 0)} onChange={handleInputChange} disabled />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="i_split i_right">
                         <div className="CGV">
                           {/* <div>
                                <h6>CONDITIONS GÉNÉRALES :</h6>
                                <div className="row">
                                    <div className="col">
                                        <p>
                                            Les séjours à YANIV sont ouverts aux membres de notre association. Les nouveaux participants doivent être membres de YANIV. La cotisation s’élève à 20€. Elle est valable du 1er octobre au 30 septembre.
                                        </p>
                                        <p>
                                            <strong>L’inscription :</strong>  nous vous conseillons de réserver vos places par téléphone ou par mail, avant tout, le nombre de places étant limité. Après cette réserva- tion, vous devez nous retourner la fiche ci-jointe, remplie et signée, accompagnée d’un chèque de 300€ sous 48h. Le paiement du solde devra être réglé 15 jours avant le départ, dès réception de la feuille de route. Un retard non justifié dans le paiement pourra conduire à l’annulation de l’inscription.
                                        </p>
                                        <p>
                                            <strong>Respect des Ages :</strong> Les âges indiqués pour chaque séjour doivent être respectés sous peine de nullité de l’inscription. Seule la direction de YANIV peut déroger à cette règle pour l’intérêt du séjour.
                                        </p>
                                        <p>
                                            <strong>Annulation :</strong> Plus de 2 mois avant le départ, il sera retenu 20% du prix du voyage, entre 2 mois et 1 mois 50% entre 30 jours et 15 jours 60% et 100% moins de 15 jours avant le départ. Pour les USA, CANADA, ISRAEL, GRECE, ESPAGNE, 100% à moins de 35 jours. Entre 6 et 3 mois avant le départ : il sera retenu 25% du prix du voyage, entre 3 et 1 mois 60 %. Frais de dossier minimum : 100€.
                                            <br />
                                            En cas de départ anticipé d’un participant au cours d’un séjour pour quelque raison que ce soit, ce dernier, mineur ou majeur ne pourra prétendre à aucun remboursement.
                                        </p>
                                        <p>
                                            <strong>Assurance :</strong> YANIV souscrit une assurance et, pour l’étranger, un contrat d’assistance. Les frais médicaux doivent être payés par le participant ou sa famille, l’assurance complétant le remboursement de la sécurité sociale.
                                            <br />
                                            Assurance : Mutuelles du Mans. Assistance rapatriement : MONDIAL ASSISTANCE. YANIV décline toute responsabilité en cas de perte, vol ou dégradation de bagages, vêtements ou tout autre objet personnel.
                                        </p>
                                        <p>
                                            <strong>Assurance Annulation</strong> (facultative mais conseillée) :  Nous pouvons, si vous le désirez, vous souscrire une assurance annulation auprès de MMA. Si vous ne prenez pas d’assurance aucun remboursement ne sera possible. Attention, pour les voyages à l’étranger. Tarifs : France 50 €, étranger : 4,2% du prix du séjour. À souscrire impérativement au moment de l’inscription. Franchise : 100 €.
                                        </p>
                                        <p>
                                            YANIV décline toute responsabilité en cas de perte ou vol de
                                            bagages, vêtements, téléphone portable ou tout autre objet
                                            personnel.
                                        </p>
                                        <p>
                                            <strong>Important :</strong> En cas de départ anticipé d’un participant au cours
                                            d’un séjour pour quelque raison que ce soit, ce dernier, mineur ou
                                            majeur ne pourra prétendre à aucun remboursement.
                                        </p>
                                        <p>
                                            <strong>La sécurité & le comportement :</strong> C’est avant tout l’affaire de tous. Notre équipe reste toujours vigilante (animateurs formés par le SPCJ), mais chacun doit se sentir concerné. En particulier, chacun doit s’engager à respecter les règles de la vie de groupe. En cas de faute grave (vol, drogue, non respect des règles des pays d’accueil, entre autre, tapage nocturne, bizutage, vandalisme), tout participant sera exclu sans prétendre à une indemnité.
                                        </p>
                                        <p>
                                            <strong>Le projet pédagogique et éducatif :</strong> YANIV recherche une vie en groupe simple, saine, juive et sportive. La participation de chacun à la vie de tous les jours est de règle : aide à la préparation des repas, chargement du bus, etc... Nos formules étant sportives, nous ne cherchons pas à donner une image de luxe mais de chaleur et de dynamisme. YANIV vous invite à participer à l’élaboration du déroulement du voyage pour que celui-ci soit le reflet de vos projets.
                                        </p>

                                    </div>
                                    <div className="col">
                                        <p>
                                            <strong>Variation des prix :</strong> Les prix sont soumis aux variations des coûts de transport (taxes & fuel) et des taux de change. L’association YANIV pourra être conduite le cas échéant à adapter ses prix : une variation de moins de 9 % des devises ne conduira à aucun ajustement, exception faite du prix du transport. Cours du dollar US : 1,17$ pour 1€.
                                        </p>
                                        <p>
                                            YANIV se réserve le droit de modifier ou d’annuler un voyage en cas de remplissage insuffisant ou de force majeure.
                                        </p>
                                        <p>
                                            <strong>Cession de contrat :</strong> Elle peut intervenir sous certaines conditions : même séjour, même tranche d’âge. Le bénéficiaire ne peut être déjà inscrit à YANIV pour un séjour, et doit accepter les mêmes règles de comportement au sein de l’association (adhésion au projet éducatif). Frais de changements de nom: 180€ forfaitaires, ajoutés aux frais facturés par nos fournisseurs (agences, compagnies aériennes).
                                        </p>
                                        <p>
                                            <strong>Utilisation de l’image :</strong> YANIV se réserve le droit d’utiliser les photos et vidéos prises lors des séjours pour illustrer ses supports de communication, sauf avis contraire du participant, de ses parents ou de son représentant légal, et ce par écrit au plus tard 48 heures avant le début du séjour.
                                        </p>
                                        <p>
                                            <strong>RGPD :</strong> YANIV s’est mise en conformité selon la loi en vigueur. Nous consulter.
                                        </p>
                                        <p>
                                            <strong>Formalités administratives :</strong> Une feuille de route, le trousseau et la
                                            fiche sanitaire de liaison vous seront envoyés environ quinze jours
                                            avant le départ. Nous préciser si vous désirez un casque de ski (pas
                                            de supplément). Il faut être apte physiquement et psychologiquement pour participer à nos
                                            séjours et à nos activités.
                                        </p>
                                        <p>
                                            <strong>Taille des groupes :</strong> Un groupe est constitué à partir de 20 personnes inscrites.
                                        </p>
                                        <p>
                                            <strong>Formalités :</strong> Ressortissants français et Union Européenne :
                                            il faut une carte d’identité ou un passeport. En règle générale, la feuille de route vous apporte les renseignements complémentaires : fiche sanitaire, autorisation de baignades, adresses, contacts.
                                        </p>


                                        <p>

                                            <strong>USA, Canada, Israël :</strong> Il faut impérativement prévoir le formulaire ESTA (site recommandé : https://esta.cbp.dhs.gov) pour rentrer au USA et depuis le 15/03/16 le formulaire AVE pour l’entrée au Canada. Passeport personnel AU NOM DE L’ENFANT en cours de validité pour au moins six mois et adapté à la nouvelle réglementation (USA en particulier).
                                            Il faut être apte psychologiquement et physiquement pour participer à nos séjours. En particulier : savoir nager pour le Canada, accepter le camping aménagé ou sauvage, la cuisine sur feu de bois, les activités de pleine nature ; savoir se protéger du soleil, bref, accepter ce qui fait le charme de YANIV et dans tous les cas avoir une autonomie suffisante en fonction de l’âge. <br />
                                            En cas de difficultés, n’hésitez pas à nous appeler : nous avons certainement des solutions.<br />
                                            <br /><br />
                                            Possibilité de recourir à la médiation : Après avoir saisi le service (après-vente, après voyage...) et à défaut de réponse satisfaisante ou en absence de réponse dans un délais de 60 jours, le client peut saisir le médiateur du Tourisme et du Voyage, dont les coordonnées et modalités de saisine sont disponibles sur son site : www.mtv.travel
                                            <br /><br />
                                            Association YANIV <br />
                                            17-19 rue des deux frères 78150 Le Chesnay, FRANCE.<br />
                                            Tél. : 01 39 63 33 01 / www.yaniv.fr / contact@yaniv.fr<br />
                                            <br /><br />
                                            Agrément tourisme : IM078100034 / Agrément Education Populaire : 78 745 / Siret : 33028740000023. Garantie bancaire: APST, 15 avenue Carnot 75017 PARIS. Médiateur : www.mtv.travel / Assurance professionnelle : MMA, 14 bd Marie et Alexandre OYON 72030 Le Mans.

                                        </p>



                                    </div>
                                </div>
                            </div>
                             */}
                            <div className="je_soussigne i_centered">
                                <div className="row">
                                    <label style={{ paddingi_right: '4px', marginTop: '5px', }}>Je soussigné(e) :</label>
                                    <input type="text" className="col-sm-7 form-control" name="s_name" value={values.s_name} onChange={handleInputChange} />
                                </div>
                            </div>
                            <strong className="i_centered" style={{ textAlign: 'justify', fontSize: '15px', paddingTop: '12px' }}>

                                <div className="col-sm-10">
                                    <br />
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" required checked={!match.params.ref ? null : true} />
                                        <label className="custom-control-label" htmlFor="customCheck1">En tant que représentant légal de l’enfant déclare avoir pris connaissance des
                                            <a onClickCapture={() => { $('#cg').modal('show') }}> <ins>conditions générales (ici)</ins></a> ainsi que du projet éducatif de YANIV et m’engage à les respecter.</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck2" required checked={!match.params.ref ? null : true} />
                                        <label className="custom-control-label" htmlFor="customCheck2">J’autorise mon enfant (ma pupille), à participer aux activités organisées pendant le séjour, sachant
                                            que
                                            toutes les conditions de sécurité seront prises. J’autorise la direction de YANIV à faire pratiquer
                                            en cas
                                            d’urgence toute opération ou hospitalisation nécessitée par son état, et sur décision du médecin.</label>
                                    </div>
                                </div>
                            </strong>
                            <div className="je_soussigne i_centered">
                                <div className="row">
                                    <label style={{ paddingi_right: '4px', marginTop: '5px' }}>Fait a :</label>
                                    <input type="text" className="col-sm form-control" name="s_location" value={values.s_location} onChange={handleInputChange} />
                                    <div style={{ paddingi_left: '10px' }}></div>
                                    <label style={{ paddingi_right: '4px', marginTop: '5px' }}>Le :</label>
                                    <input type="text" className="col-sm form-control" name="s_date" value={values.s_date} onChange={handleInputChange} disabled />
                                </div>
                            </div>
                            <div className="submit i_centered">
                                {showInfo ? null : <input type="submit" value="Valider" />}
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            <div className="modal fade" id="confirmation" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Finalisation de l'inscription</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick="window.location.replace('yaniv.fr')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Afin de finaliser votre inscription, nous vous invitons à régler l'acompte a travers notre plateforme de paiements sécurisé en cliquant sur le lien dans le mail que vous avez reçu par mail.
                            <br />
                            Dans le cas ou vous souhaitez payer un autre montant ou avec un autre moyen de paiement (cheques, espèces, chèque vacances), nous vous invitons à nous contacter par téléphone au 01 39 63 33 01.
                            <br />
                            <small>Si vous avez déja payé l'acompte, merci de ne pas tenir compte de ce message, la place de votre enfant sera confirmée automatiquement.</small>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={redirect}>Suivant</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="cg" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Conditions Générales</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick="window.location.replace('yaniv.fr')">
                                <span aria-hidden="true">✓</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            LES SÉJOURS YANIV SONT OUVERTS AUX MEMBRES DE NOTRE ASSOCIATION. LA COTISATION S’ÉLÈVE À 20 € ET EST VALABLE DU 1ER OCTOBRE AU 30 SEPTEMBRE.
                            L’INSCRIPTION : nous vous conseillons de réserver vos places par téléphone ou par mail, avant tout, le nombre de places étant limité. Après cette réserva- tion, vous devez nous retourner la fiche ci-jointe, remplie et signée, accompagnée d’un chèque de 300€ sous 48h. Le paiement du solde devra être réglé 15 jours avant le départ, dès réception de la feuille de route. Un retard non justifié dans le paiement pourra conduire à l’annulation de l’inscription.
                            LE PROJET ÉDUCATIF : YANIV recherche une vie en groupe saine, simple, juive et sportive. La participation de chacun à la vie de tous les jours est de règle. Nos formules étant sportives, nous ne cherchons pas à donner une image de luxe, mais de chaleur et de dynamisme. Tout manquement aux règles de bon comportement (vols, dégradations, drogue, insolence grave,...) pourra conduire à l’exclusion de l’enfant, sans prétendre à aucun remboursement ni indemnité.
                            ANNULATION : Un mois avant le départ 30%, entre un mois et 10 jours : 50%. Si l’annulation nous parvient 10 jours avant le départ, la totalité du montant du séjour sera retenue. Dans tous les cas un forfait de 115€ sera retenu pour les frais d’inscription. En cas de départ anticipé d’un participant au cours d’un séjour pour quelque raison que ce soit, ce dernier mineur ou majeur ne pourra prétendre à aucun remboursement.
                            TAILLE DU GROUPE : Un groupe est constitué à partir de 20 personnes inscrites.
                            LA SÉCURITÉ & LE COMPORTEMENT : C’est avant tout l’affaire de tous. Notre équipe reste toujours vigilante (animateurs formés par le SPCJ), mais chacun doit se sentir concerné.
                            En particulier, chacun doit s’engager à respecter les règles de la vie de groupe. En cas de faute grave (vol, drogue, non respect des règles des pays d’accueil, entre autre, tapage nocturne, bizutage, vandalisme), tout participant sera exclu sans prétendre à une indemnité.
                            UTILISATION DE L’IMAGE : YANIV se réserve le droit d’utiliser les photos et vidéos prises lors des séjours pour illustrer ses supports de communication, sauf avis contraire du participant, de ses parents ou de son représentant légal, et ce par écrit au plus tard 48 heures avant le début du séjour.
                            CESSION DE CONTRAT : Elle peut intervenir sous certaines conditions : même séjour, même tranche d’âge. Le bénéficiaire ne peut être déjà inscrit à YANIV pour un séjour, et doit accepter les mêmes règles de comportement au sein de l’association (adhésion au projet éducatif). Frais de changements de nom: 180€ forfaitaires, ajoutés aux frais facturés par nos fournisseurs (agences, compagnies aériennes).
                            ASSURANCE : YANIV souscrit une assurance RC et rapatriement auprès de M.M.A. Les frais médicaux doivent être réglés par le participant , l’assurance complétant le remboursement de Sécurité Sociale, en cas d’accident. Il vous est possible de prendre une assurance annulation pour un prix de 45 €. Contactez-nous. YANIV décline toute responsabilité en cas de vol ou perte de bagages que nous vous conseillons d’étiqueter.
                            Les responsables de YANIV se tiennent à votre disposition pour tous renseignements complémentaires et inscriptions :
                            01 39 63 33 01
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={redirect}>Suivant</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Inscription;