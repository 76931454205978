import logo from '../img/logo_ministere_jeunesse_et_sport.png'
import $ from 'jquery';
import 'bootstrap/js/dist/modal.js';
import { projectFirestore } from '../firebase'
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import '../fichesanitaire.css';

function FicheSanitaire({ match }) {

    const history = useHistory();

    const initialFieldValue = {
        nom: '',
        prenom: '',
        birthdate: '',
        sexe: '',
        v_diphterie: '',
        v_diphterie_date: '',
        v_tetanos: '',
        v_tetanos_date: '',
        v_poliomyelite: '',
        v_poliomyelite_date: '',
        v_autre_date: '',
        v_bcg_date: '',
        v_pneumocoque_date: '',
        v_hepatiteb_date: '',
        v_ror_date: '',
        v_haemophilus_date: '',
        v_coqueluche_date: '',
        poids: '',
        taille: '',
        traitement: '',
        a_alimentaires: '',
        a_alimentaires_details: '',
        a_medicamenteuses: '',
        a_medicamenteuses_details: '',
        a_autres: '',
        a_autres_details: '',
        probleme_de_sante: '',
        probleme_de_sante_details: '',
        commentaire: '',

        resp1_nom: '',
        resp1_prenom: '',
        resp1_adresse: '',
        resp1_tel_domicile: '',
        resp1_tel_travail: '',
        resp1_tel_portable: '',
        resp2_nom: '',
        resp2_prenom: '',
        resp2_adresse: '',
        resp2_tel_domicile: '',
        resp2_tel_travail: '',
        resp2_tel_portable: '',

        info_medecin: '',


        s_date: new Date().toLocaleDateString('fr-FR'),
        s_name: '',
        s_location: '',
    }

    var [values, setValues] = useState(initialFieldValue);
    var [showInfo, setShowInfo] = useState();
    var [validate, setValidate] = useState(true);

    useEffect(async () => {
        setValues({ ...initialFieldValue })
        await projectFirestore.collection('i_inscriptions').doc(match.params.id).get().then(
            async (doc) => {
                if (doc.exists && doc.data().ref == match.params.ref && doc.data().fs_a_remplir == true) {
                    projectFirestore.collection('i_inscriptions').doc(match.params.id).collection('Info').doc('fiche_sanitaire').get().then(
                        async (doc) => {
                            if (!doc.exists) {
                                history.push("/404")
                            } else {
                                setValues({
                                    ...doc.data()
                                })
                            }
                        }
                    )
                    setShowInfo(true);
                } else if (!doc.exists || doc.data().fs_a_remplir != true || doc.data().fs_remplie != false) {
                    history.push("/404")
                } else {
                    setValues({
                        ...values,
                        nom: doc.data().nom,
                        prenom: doc.data().prenom,
                        birthdate: new Date(doc.data().birthdate.seconds * 1000).toISOString().slice(0, 10),
                    })

                }
                setValidate(doc.data().fs_verifier ? false : true)
            }
        )
        // eslint-disable-next-line
    }, [match.params]);

    const handleInputChange = e => {
        var { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleInputSubmit = async e => {
        e.preventDefault();
        setShowInfo(false);
        await projectFirestore.collection('i_inscriptions').doc(match.params.id).collection('Info').doc('fiche_sanitaire').set(values);
        await projectFirestore.collection('i_notifications').doc().set({
            createdAt: new Date(),
            tag: 'fs_to_validate',
            message: "Fiche Sanitaire de " + values.nom + " " + values.prenom + "a verifier",
            archived: false,
        });
        await projectFirestore.collection('i_inscriptions').doc(match.params.id).update({ fs_remplie: true, lastUpdated: new Date() });
        $('#confirmation').modal('show')
    }

    const redirect = () => {
        setShowInfo(false);
        window.location.href = 'https://yaniv.fr'
    }

    return (
        <>
            <div className="i_centered mt-3" style={{ position: 'absolute', zIndex: 9 }}>
                {showInfo && match.params.ref ? <input type="button" className="btn btn-warning" value="Telecharger" onClick={
                    () => {
                        const input = document.getElementById("test");

                        html2canvas(input, {})
                            .then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                var doc = document.createElement('a');
                                doc.download = 'FicheSanitaire_' + values.nom + '_' + values.prenom + '.png';
                                doc.href = imgData;
                                doc.click();
                            });
                    }
                } /> : null}


                {/* showInfo && match.params.ref && validate ? <input type="button" className="btn btn-success ml-5" value="Valider La Fiche D'inscription" onClick={
                    async () => {
                        await projectFirestore.collection('i_inscriptions').doc(match.params.id).update({ fs_verifier: true, fs_a_remplir: true, fs_remplie: true })
                        setValidate(false);
                    }
                } /> : null */}
            </div>
            <form onSubmit={handleInputSubmit}>
                <div className="wrapper2" id="test">
                    <div class="s_split s_left">
                        <div class="row s_centered">
                            <div class="col-sm-2">
                                <img src={logo} alt="logo ministere des jeunesse et du sport" />
                            </div>
                            <div class="col-sm en-tete">
                                <div class="content">
                                    <div class="row s_centered">
                                        <h5>FICHE SANITAIRE</h5>
                                    </div>
                                    <div class="row s_centered">
                                        <h6>DOCUMENT CONFIDENTIEL</h6>
                                    </div>
                                    <div class="row s_centered">
                                        <h7>Joindre obligatoirement la copie du carnet de vaccination</h7>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <br /> <br />
                        <div class="row input-group">
                            <div class="col">
                                <label>Nom du Mineur :</label>
                                <input type="text" class="col-sm form-control" name="nom" value={values.nom} onChange={handleInputChange} disabled />
                            </div>
                            <div class="col">
                                <label>Prénom :</label>
                                <input type="text" class="col-sm form-control" name="prenom" value={values.prenom} onChange={handleInputChange} disabled />
                            </div>
                            <div class="col">
                                <label>Date de naissance :</label>
                                <input type="date" class="col-sm form-control" name="birthdate" value={values.birthdate} onChange={handleInputChange} disabled />
                            </div>
                            <div class="col-sm-2">
                                <label>Sexe :</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="sexe" id="F" value="F" checked={values.sexe == 'F' ? true : false} onChange={handleInputChange} required />
                                    <label class="form-check-label" for="F">F</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="sexe" id="M" value="M" checked={values.sexe == 'M' ? true : false} onChange={handleInputChange} required />
                                    <label class="form-check-label" for="M">M</label>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: '5px' }}></div>
                        <small>Cette fiche permet de recueillir des information utiles concernant votre enfant (l'arrêté
                        du 20 fevrier 2003 relatif au suivi sanitaire des mineurs en séjour de vacances ou en acceuil de
                        loisirs).</small>
                        <div style={{ paddingTop: '10px' }}></div>
                        <h5>1- VACCINATIONS <small>(se référer au carnet de santé ou certificats de vaccinations)</small></h5>

                        <div class="grid">
                            <div class="row input-group">
                                <div class="col-sm grid-element">
                                    <h6>VACCINATIONS OBLIGATOIRE :</h6>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <h6>Oui</h6>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <h6>Non</h6>
                                </div>
                                <div class="col-sm grid-element">
                                    <h6>DATES DES DERNIERS RAPPELS</h6>
                                </div>
                                <div class="col-sm grid-element">
                                    <h6>VACCINS RECOMMENDÉS :</h6>
                                </div>
                                <div class="col-sm grid-element">
                                    <h6>DATES</h6>
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm grid-element">
                                    <span>Diphtérie :</span>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <div class="">
                                        <input class="" type="radio" name="v_diphterie" id="diphterie-OUI" value="OUI" checked={values.v_diphterie == 'OUI' ? true : false} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <div class="">
                                        <input class="" type="radio" name="v_diphterie" id="diphterie-NON" value="NON" checked={values.v_diphterie == 'NON' ? true : false} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_diphterie_date" value={values.v_diphterie_date} onChange={handleInputChange} />
                                </div>
                                <div class="col-sm grid-element">
                                    <span>Coqueluche</span>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_coqueluche_date" value={values.v_coqueluche_date} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm grid-element">
                                    <span>Tétanos :</span>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <div class="">
                                        <input class="" type="radio" name="v_tetanos" id="tetanos-OUI" value="OUI" checked={values.v_tetanos == 'OUI' ? true : false} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <div class="">
                                        <input class="" type="radio" name="v_tetanos" id="tetanos-NON" value="NON" checked={values.v_tetanos == 'NON' ? true : false} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_tetanos_date" value={values.v_tetanos_date} onChange={handleInputChange} />
                                </div>
                                <div class="col-sm grid-element">
                                    <span>Haemophilus</span>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_haemophilus_date" value={values.v_haemophilus_date} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm grid-element">
                                    <span>Poliomyélite :</span>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <div class="">
                                        <input class="" type="radio" name="v_poliomyelite" id="poliomyelite-OUI" value="OUI" checked={values.v_poliomyelite == 'OUI' ? true : false} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <div class="">
                                        <input class="" type="radio" name="v_poliomyelite" id="poliomyelite-NON" value="NON" checked={values.v_poliomyelite == 'NON' ? true : false} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_poliomyelite_date" value={values.v_poliomyelite_date} onChange={handleInputChange} />
                                </div>
                                <div class="col-sm grid-element">
                                    <span>Rubéole-Oreillons-Rougeole</span>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_ror_date" value={values.v_ror_date} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm grid-element"></div>
                                <div class="col-sm-1 grid-element"></div>
                                <div class="col-sm-1 grid-element"></div>
                                <div class="col-sm grid-element"></div>
                                <div class="col-sm grid-element">
                                    <span>Hépatite B</span>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_hepatiteb_date" value={values.v_hepatiteb_date} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm grid-element"></div>
                                <div class="col-sm-1 grid-element"></div>
                                <div class="col-sm-1 grid-element"></div>
                                <div class="col-sm grid-element"></div>
                                <div class="col-sm grid-element">
                                    <span>Pneumocoque</span>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_pneumocoque_date" value={values.v_pneumocoque_date} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm grid-element"></div>
                                <div class="col-sm-1 grid-element"></div>
                                <div class="col-sm-1 grid-element"></div>
                                <div class="col-sm grid-element"></div>
                                <div class="col-sm grid-element">
                                    <span>BCG</span>
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" class="form-control" name="v_bcg_date" value={values.v_bcg_date} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm grid-element grid-end"></div>
                                <div class="col-sm-1 grid-element grid-end"></div>
                                <div class="col-sm-1 grid-element grid-end"></div>
                                <div class="col-sm grid-element grid-end"></div>
                                <div class="col-sm grid-element grid-end">
                                    <span>Autres (préciser)</span>
                                </div>
                                <div class="col-sm grid-element grid-end">
                                    <input type="text" class="form-control" name="v_autre_date" value={values.v_autre_date} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <small> SI LE MINEUR N’A PAS LES VACCINS OBLIGATOIRES JOINDRE UN CERTIFICAT MÉDICAL DE CONTREINDICATION. </small>
                        <div style={{ paddingTop: '10px' }}></div>
                        <h5>2- RENSEIGNEMENTS CONCERNANT LE MINEUR</h5>
                        <div class="row">
                            <div class="col-sm-3">
                                <label for="poids">Poids : <br /><small>(kg)</small></label>
                                <input type="number" name="poids" aria-describedby="poids" id="poids" class="col-sm form-control" value={values.poids} onChange={handleInputChange} required />
                            </div>
                            <div class="col-sm-4">
                                <label for="taille">Taille : <br /> <small>(informations nécessaires en cas d'urgence)</small></label>
                                <input type="number" name="taille" aria-describedby="taille" id="taille" class="col-sm form-control" value={values.taille} onChange={handleInputChange} required />
                            </div>
                            <div class="col-sm">
                                <label><br />Suit-il un traitement médical pendant le séjour ?</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="traitement" id="traitement-OUI" value="OUI" checked={values.traitement == 'OUI' ? true : false} onChange={handleInputChange} required />
                                    <label class="form-check-label" for="traitement-OUI">Oui</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="traitement" id="traitement-NON" value="NON" checked={values.traitement == 'NON' ? true : false} onChange={handleInputChange} required />
                                    <label class="form-check-label" for="traitement-NON">Non</label>
                                </div>
                            </div>
                        </div>
                        <small>
                            Si oui, joindre une ordonnance récente et les médicaments correspondants (boîtes de
                            médicaments dans leur emballage d'origine marquées au nom de l'enfant avec la notice). <br />
                            <strong>Aucun médicament ne pourra être administré sans ordonnance.</strong>
                        </small>
                        <div class="grid">
                            <div class="row input-group">
                                <div class="col-sm-3 grid-element-left"><span><strong>ALLERGIES :</strong></span></div>
                                <div class="col-sm-1 grid-element"><span>Oui</span></div>
                                <div class="col-sm-1 grid-element"><span>Non</span></div>
                                <div class="col-sm grid-element-left"><span>Préciser :</span></div>
                            </div>

                            <div class="row input-group">
                                <div class="col-sm-3 grid-element">
                                    <span>ALIMENTAIRES</span>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <input type="radio" name="a_alimentaires" id="a_alimentaires" value="OUI" checked={values.a_alimentaires == 'OUI' ? true : false} onChange={handleInputChange} required />
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <input type="radio" name="a_alimentaires" id="a_alimentaires" value="NON" checked={values.a_alimentaires == 'NON' ? true : false} onChange={handleInputChange} required />
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" name="a_alimentaires_details" class="form-control" id="a_alimentaires_details" value={values.a_alimentaires_details} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm-3 grid-element">
                                    <span>MEDICAMENTEUSES</span>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <input type="radio" name="a_medicamenteuses" id="a_medicamenteuses" value="OUI" checked={values.a_medicamenteuses == 'OUI' ? true : false} onChange={handleInputChange} required />
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <input type="radio" name="a_medicamenteuses" id="a_medicamenteuses" value="NON" checked={values.a_medicamenteuses == 'NON' ? true : false} onChange={handleInputChange} required />
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" name="a_medicamenteuses_details" class="form-control" id="a_medicamenteuses_details" value={values.a_medicamenteuses_details} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div class="row input-group">
                                <div class="col-sm-3 grid-element grid-end">
                                    <span>AUTRES <small>(animaux, plantes, pollen)</small> :</span>
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <input type="radio" name="a_autres" id="a_autres" value="OUI" checked={values.a_autres == 'OUI' ? true : false} onChange={handleInputChange} required />
                                </div>
                                <div class="col-sm-1 grid-element">
                                    <input type="radio" name="a_autres" id="a_autres" value="NON" checked={values.a_autres == 'NON' ? true : false} onChange={handleInputChange} required />
                                </div>
                                <div class="col-sm grid-element">
                                    <input type="text" name="a_autres_details" class="form-control" id="a_autres_details" value={values.a_autres_details} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <small>Si oui, joindre un précisant la cause de l’allergie, les signes évocateurs et la
                            conduite à tenir.</small>
                    </div>


                    <div class="s_split s_right">
                        <div class="row">
                            <div class="col-sm-8">
                                <span>Le mineur présente-t-il un problème de santé, si oui préciser :</span>
                            </div>
                            <div class="col-sm-1 grid-element">
                                <input type="radio" name="probleme_de_sante" id="probleme_de_sante-OUI" value="OUI" checked={values.probleme_de_sante == 'OUI' ? true : false} onChange={handleInputChange} required />
                                <label for="probleme_de_sante-OUI">Oui</label>
                            </div>
                            <div class="col-sm-2 grid-element">
                                <input type="radio" name="probleme_de_sante" id="probleme_de_sante-NON" value="NON" checked={values.probleme_de_sante == 'NON' ? true : false} onChange={handleInputChange} required />
                                <label for="probleme_de_sante-NON">Non</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <textarea name="probleme_de_sante_details" id="probleme_de_sante_details" cols="105" rows="4" value={values.probleme_de_sante_details} onChange={handleInputChange}></textarea>
                            </div>
                        </div>
                        <br />
                        <h5>3- RECOMMANDATIONS UTILES DES PARENTS</h5>
                        <div class="row">
                            <div class="col-sm-11">
                                <span>Port des lunettes, de lentilles, d'appareil dentaire ou auditif, comportement de l'enfant,
                            difficultés de sommeil, énurésie nocturne, etc…</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <textarea name="commentaire" id="commentaire" cols="105" rows="4" value={values.commentaire} onChange={handleInputChange}></textarea>
                            </div>
                        </div>
                        <br />
                        <h5>4- RESPONSABLES DU MINEUR</h5>
                        <h6>Résponsable n°1 :</h6>
                        <div class="row input-group">
                            <div class="col">
                                <label>Nom :</label>
                                <input type="text" class="col-sm form-control" name="resp1_nom" value={values.resp1_nom} onChange={handleInputChange} required />
                            </div>
                            <div class="col">
                                <label>Prénom :</label>
                                <input type="text" class="col-sm form-control" name="resp1_prenom" value={values.resp1_prenom} onChange={handleInputChange} required />
                            </div>
                            <div class="col">
                                <label>Adresse :</label>
                                <input type="text" class="col-sm form-control" name="resp1_adresse" value={values.resp1_adresse} onChange={handleInputChange} required />
                            </div>
                        </div>
                        <div class="row input-group">
                            <div class="col">
                                <label>Tel Domicile :</label>
                                <input type="text" class="col-sm form-control" name="resp1_tel_domicile" value={values.resp1_tel_domicile} onChange={handleInputChange} required />
                            </div>
                            <div class="col">
                                <label>Tel Travail :</label>
                                <input type="text" class="col-sm form-control" name="resp1_tel_travail" value={values.resp1_tel_travail} onChange={handleInputChange} required />
                            </div>
                            <div class="col">
                                <label>Tel Portable :</label>
                                <input type="text" class="col-sm form-control" name="resp1_tel_portable" value={values.resp1_tel_portable} onChange={handleInputChange} required />
                            </div>
                        </div>
                        <br />
                        <h6>Résponsable n°2 :</h6>
                        <div class="row input-group">
                            <div class="col">
                                <label>Nom :</label>
                                <input type="text" class="col-sm form-control" name="resp2_nom" value={values.resp2_nom} onChange={handleInputChange} />
                            </div>
                            <div class="col">
                                <label>Prénom :</label>
                                <input type="text" class="col-sm form-control" name="resp2_prenom" value={values.resp2_prenom} onChange={handleInputChange} />
                            </div>
                            <div class="col">
                                <label>Adresse :</label>
                                <input type="text" class="col-sm form-control" name="resp2_adresse" value={values.resp2_adresse} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div class="row input-group">
                            <div class="col">
                                <label>Tel Domicile :</label>
                                <input type="text" class="col-sm form-control" name="resp2_tel_domicile" value={values.resp2_tel_domicile} onChange={handleInputChange} />
                            </div>
                            <div class="col">
                                <label>Tel Travail :</label>
                                <input type="text" class="col-sm form-control" name="resp2_tel_travail" value={values.resp2_tel_travail} onChange={handleInputChange} />
                            </div>
                            <div class="col">
                                <label>Tel Portable :</label>
                                <input type="text" class="col-sm form-control" name="resp2_tel_portable" value={values.resp2_tel_portable} onChange={handleInputChange} />
                            </div>
                        </div>
                        <br />
                        <label>NOM ET TEL MEDECIN TRAITANT :</label>
                        <input type="text" class="col-sm form-control" name="info_medecin" value={values.info_medecin} onChange={handleInputChange} />
                        <br />
                        <div className="je_soussigne i_centered">
                            <div className="row">
                                <label style={{ paddingRight: '4px', marginTop: '5px', }}>Je soussigné(e) :</label>
                                <input type="text" className="col-sm-7 form-control" name="s_name" value={values.s_name} onChange={handleInputChange} required />
                            </div>
                        </div>
                        <strong className="i_centered" style={{ textAlign: 'justify', fontSize: '15px', paddingTop: '12px' }}>

                            <div className="col-sm-10">
                                <br />
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" required checked={!match.params.ref ? null : true} />
                                    <label className="custom-control-label" htmlFor="customCheck1">En tant que représentant légal de l’enfant déclare exacts les renseignements portés sur cette fiche et m'engage à les réactualiser si nécessaires.</label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck2" required checked={!match.params.ref ? null : true} />
                                    <label className="custom-control-label" htmlFor="customCheck2">J’autorise le responsable de l'acceuil de loisirs à prendre, la cas échéant, toutes mesures rendues nécessaires selon l'état de santé de ce mineur.</label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck3" required checked={!match.params.ref ? null : true} />
                                    <label className="custom-control-label" htmlFor="customCheck3">Je comprends que ces informations confidentiel seront sauvegardées sur les serveurs de l'ASSOCIATION YANIV.</label>
                                </div>
                            </div>
                        </strong>
                        <div className="je_soussigne i_centered">
                            <div className="row">
                                <label style={{ paddingRight: '4px', marginTop: '5px' }}>Fait a :</label>
                                <input type="text" className="col-sm form-control" name="s_location" value={values.s_location} onChange={handleInputChange} required />
                                <div style={{ paddingi_left: '10px' }}></div>
                                <label style={{ paddingRight: '4px', marginTop: '5px' }}>Le :</label>
                                <input type="text" className="col-sm form-control" name="s_date" value={values.s_date} onChange={handleInputChange} disabled required />
                            </div>
                        </div>
                        <div class="submit" style={{ textAlign: 'right' }}>
                            {showInfo ? null : <input type="submit" class="btn btn-outline-dark" value="Valider" />}
                        </div>
                    </div>
                </div>
            </form>
            <div className="modal fade" id="confirmation" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Bonjour, 
                            Votre fiche sanitaire à bien été prise en compte.
                            Nous vous invitons à donner à votre enfant le jour J les différents documents (certificat médical, photocopie carnet de vaccination, ordonnance....), ou à les envoyer à contact@yaniv.fr.<br />
                            Merci d'avance !
                         </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={redirect}>Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FicheSanitaire;