import { projectFirestore } from '../firebase'
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';

function PaiementRedirection({ match }) {

    const history = useHistory();

    useEffect(async () => {
        await projectFirestore.collection('i_inscriptions').doc(match.params.i_id).collection('paiements').doc(match.params.p_id).get().then(
            async (doc) => {
                if (doc.exists && doc.data().ref == match.params.ref) {
                    redirect(doc.data())
                } else {
                    history.push("/404")
                }
            }
        )
        // eslint-disable-next-line
    }, [match.params]);

    const redirect = async (obj) => {
        window.location.replace(
            'https://sogecommerce.societegenerale.eu/vads-site/YANIV?ctx_mode=TEST&lck_vads_amount=' + obj.montant
            + '&lck_vads_cust_first_name=' + obj.prenom
            + '&lck_vads_cust_last_name=' + obj.nom
            + '&lck_vads_ext_info_Date%20de%20naissance=' + new Date(obj.birthdate.seconds * 1000).toLocaleDateString("fr-FR")
            + '&lck_vads_order_info=' + obj.sejour
            + '&lck_vads_cust_id=' + obj.ref
        );
    }

    return (
        <>
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
        </>
    );
}

export default PaiementRedirection;