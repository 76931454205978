import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PreInscription from './components/PreInscription';
import Inscription from './components/Inscription';
import PaiementRedirection from './components/PaiementRedirection';
import FicheSanitaire from './components/FicheSanitaire';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={PreInscription} />
        <Route path="/fi/:id/:ref?" exact component={Inscription} />
        <Route path="/fs/:id/:ref?" exact component={FicheSanitaire} />
        <Route path="/p/:i_id/:p_id/:ref" exact component={PaiementRedirection} />
        <Route path="/404" exact component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;