function NotFound() {

    return (
        <>
            <div class="d-flex justify-content-center align-items-center" id="main" style={{height: '100vh'}}>
                <h1 class="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                <div class="inline-block align-middle">
                    <h2 class="font-weight-normal lead" id="desc">La page que vous avez demandée n'a pas été trouvée.</h2>
                </div>
            </div>
        </>
    );
}

export default NotFound;